import { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import "bootstrap/dist/css/bootstrap.min.css";
import Axios from 'axios';
import { Timeline } from 'react-twitter-widgets'
import { FacebookProvider, Page } from 'react-facebook';
import { Element } from 'react-scroll';
import splash from './ultilidades/splash'
import Modales from './ultilidades/Modales'
import Directorio from './ultilidades/Directorio'
import alerta from './ultilidades/alerta'
import { useSelector } from 'react-redux'

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

function Inicio() {
  const personalRedux = useSelector((state) => state.personal.value)
  const [select, setSelect] = useState({
    slider: [],
    sintesis: [],
    boletin: [],
  });
  const [time, setTime] = useState(new Date().toLocaleTimeString());
  const [directorio, setDirectorio] = useState({
    ver: false,
    tipo: "",
    titulo: "",
  })
  const [modal, setModal] = useState({
    ver: false,
    tipo: "",
    url: "",
    titulo: "",
    arreglo: [],
  })
  const [modalID, setModalID] = useState(0);

  const tiempode = () => {
    setTime(new Date().toLocaleTimeString())
  }

  const selecSlider = () => {
    window.H5_loading.show();
    var pasar = new URLSearchParams();
    pasar.append('_USUARIO', localStorage.usuario);
    pasar.append('_ROL', localStorage._ROL);
    pasar.append('_Activa', localStorage._Activa);

    Axios.post(`${window.ws}wsSelect.php`, pasar)
      .then(response => {
        if(response.data.elError===1){
          setSelect({
            slider: response.data.slider_array,
            sintesis: response.data.sintesis_array,
            boletin: response.data.boletin_array,
          })
          //alerta('success', 'si'+response.data.mensaje)
        }else{
          //alerta('error', response.data.mensaje)
          console.log(response.data.mensaje)
        }
      })
      .catch(error => {
        //alerta('error', ''+error)
        console.log(error)
      })
      .then(function() {
        window.H5_loading.hide();
      })
  }

  let initialized = false
  useEffect(() => {
    selecSlider()

    setInterval(() => {
      tiempode();
    }, 1000);

    if (!initialized) {
      initialized = true
      splash()
    }
  }, []);

  let cumple = personalRedux.filter(p => p.per_rfc.slice(6, 10)===(window.nmes+window.date.getDate()))
 
  const arreglo_sort = Array.from(personalRedux)
  arreglo_sort.sort(
    (a,b) => Date.parse(`${window.ano}-${a.per_rfc.slice(6, 8)}-${a.per_rfc.slice(8, 10)}T00:00`) - Date.parse(`${window.ano}-${b.per_rfc.slice(6, 8)}-${b.per_rfc.slice(8, 10)}T00:00`)
  )
  let cumple_prox = arreglo_sort.filter(
    p => {
      let date = new Date(`${window.ano}-${p.per_rfc.slice(6, 8)}-${p.per_rfc.slice(8, 10)}T00:00`);
      return (date>window.date);
    }
  ).slice(0, window.dias_cumple)

  //let festivos = window._festivos.filter(p => p.fecha.slice(5, 7)===window.nmes) //mes actual
  //var startDate = new Date("2023-01-01T00:00");
  //var endDate = new Date("2023-01-27T00:00");
  
  let festivos = window._festivos.filter(
    /*
    p => {
      let date = new Date(`${p.fecha}T00:00`);
      return (date >= window.date && date <= endDate);
    }
    */
    p => {
      let date = new Date(`${p.fecha}T00:00`);
      return (date>=window.date);
    }
  ).slice(0, window.dias_cumple)

  return (
    <div className="contenido_general">
      <div className="max_width padding_width">
        <div className="intranet_uno" data-aos="fade-right">
          {
            select.slider.length ?
              <Carousel className="slider_intranet" fade nextIcon={<img src="img/next.svg" className="caro_next" alt="Siguiente"/>} prevIcon={<img src="img/next.svg" className="caro_next caro_prev" alt="Atras"/>}>
                {
                  select.slider.map((item, i) =>
                    <Carousel.Item key={i}>
                      {
                        item.sl_link && item.sl_tipo===0 ?
                          <a href={item.sl_link} target="new">
                            <img src={`${window.ws}documentos/slider/${item.sl_id}.${item.sl_foto}`} alt="Slider"/>
                          </a>
                        :
                          <img src={`${window.ws}documentos/slider/${item.sl_id}.${item.sl_foto}`} alt="Slider"/>
                      }
                      <Carousel.Caption>
                        {item.sl_titulo ? <h3>{item.sl_titulo}</h3> : null}
                        {item.sl_texto ? <p>{item.sl_texto}</p> : null}
                        {item.sl_link && item.sl_tipo>0 ? <a href={item.sl_link} className="url_slider" target="new">Ver más</a> : null}
                      </Carousel.Caption>
                    </Carousel.Item>
                  )
                }
              </Carousel>
            :
              <img className="sin_slider" src="img/slider.jpg" alt="Slider"/>
          }
          <div className="alado_slider" data-aos="fade-left">
            <div className="el_clima" onClick={() => setModal({
              ver: true,
              tipo: "frame",
              url: "https://www.accuweather.com/es/mx/pachuca-de-soto/233138/daily-weather-forecast/233138",
              titulo: "El clima en Pachuca de Soto",
            })}>
              <div className="logo_clima">
                <img src={time>"18:30:00" || time<"05:00:00" ? "img/clima_luna.svg" : "img/clima_sol.svg"} alt="Clima"/>
                <div>Ver el clima</div>
              </div>
              <div className="el_reloj">
                BIENVENIDO
                <h1>{time}</h1>
                {`${window.dia[window.date.getDay()]} ${window.date.getDate()} de ${window.meses[window.date.getMonth()]}`}
              </div>
            </div>
            {
              select.sintesis.length>0 ?
                <div className="caja_sintesis" onClick={() => {
                  setModal({
                    ver: true,
                    tipo: "sintesis",
                    titulo: "Síntesis informativa",
                    arreglo: select.sintesis,
                  })
                  setModalID(select.sintesis.slice(0, 1)[0].sin_id)
                }}>
                  <img src="img/sintesis.svg" alt="Síntesis informativa" title="Síntesis informativa"/>
                  <div>
                    <h7>Síntesis informativa</h7>
                    <br/>
                    Comunicación social
                  </div>
                </div>
              :
                null
            }
            {
              select.boletin.length>0 ?
                <div className="caja_sintesis sin_dos" onClick={() => {
                  setModal({
                    ver: true,
                    tipo: "sintesis",
                    titulo: "Reporte económico",
                    arreglo: select.boletin,
                  })
                  setModalID(select.boletin.slice(0, 1)[0].sin_id)
                }}>
                  <img src="img/mapa_hgo.svg" alt="Boletín" title="Boletín"/>
                  <div>
                    <h7>Reporte económico</h7>
                    <br/>
                    Dirección de Planeación
                  </div>
                </div>
              :
                null
            }
          </div>
        </div>
        <Element name="redes"></Element>
        <div className="titulos_intranet intranet_mtop">
          Redes sociales
        </div>
        <div className="twi_face">
          {
            window.screen.width>1100 ?
              <div>
                <FacebookProvider appId="2437438926404457">
                  <Page href={window._face_secretaria} tabs="timeline" height="400"/>
                </FacebookProvider>
              </div>
            :
              <div className="espacio_twi">
                <Timeline
                  dataSource={{
                    sourceType: 'profile',
                    screenName: window._twi_secretaria,
                  }}
                  options={{
                    height: '400'
                  }}
                />
              </div>

          }
          <div className="espacio_twi">
            <Timeline
              dataSource={{
                sourceType: 'profile',
                screenName: window._twi_gobernador,
              }}
              options={{
                height: '400'
              }}
            />
          </div>
          <div className="espacio_twi">
            <Timeline
              dataSource={{
                sourceType: 'profile',
                screenName: window._twi_secretario,
              }}
              options={{
                height: '400'
              }}
            />
          </div>
        </div>
        {
          personalRedux.length>0 || window._festivos.length>0 ?
            <div className="intranet_festejos intranet_mtop">
              {
                cumple.length>0 ?
                  <div className="cumple_hoy" data-aos="fade-right">
                    <div className="titulos_intranet">
                      Cumpleaños de hoy
                    </div>
                    <div className="cumplidos">
                      <div>
                        {
                          cumple.length>1 ?
                            cumple.map((item, i) =>
                              <div className="cumple_varios" key={i} onClick={() => {
                                setModal({
                                  ver: true,
                                  tipo: "cumples",
                                  titulo: "Enviar felicitación",
                                })
                                setModalID(item.per_id)
                              }}>
                                <img src={item.per_foto ? `${window.ws}documentos/fotos/F_${item.per_id}.${item.per_foto}` : `img/ico.svg`} alt="Fotografía"/>
                                <div>
                                  <h14>{item.completo}</h14>
                                  {
                                    item.comi_area ? 
                                      <h15>{window._my_organismos.filter(p => p.id===item.comi_area)[0].nombre}</h15>
                                    : item.per_c_area ?
                                      <h15>{window._my_organismos.filter(p => p.id===item.per_c_area)[0].nombre}</h15>
                                    : null
                                  }
                                  <div className="enviar_felicitacion">
                                    <div>
                                      <img src="img/m_correo.svg" alt="Correo"/>
                                      Felicitar
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          :
                            <div className="cumple_uno">
                              <img src={cumple[0].per_foto ? `${window.ws}documentos/fotos/${cumple[0].per_foto}` : `img/ico.svg`} alt="Fotografía"/>
                              <div>{cumple[0].completo}</div>
                            </div>
                        }
                      </div>
                    </div>
                  </div>
                :
                  null
              }
              <div className={cumple.length>0 ? "cumple_mes" : festivos.length>0 ? "cumple_mes_dos" : "cumple_mes_tres"} data-aos="fade-up">
                <div className="titulos_intranet">
                  Próximos cumpleaños
                </div>
                <div className="prox_cumpleanos">
                  <table className="carrillo">
                    {
                      cumple_prox.map((item, i) => {
                        let miFecha = new Date(`${window.ano}-${item.per_rfc.slice(6, 8)}-${item.per_rfc.slice(8, 10)}T00:00`)
                        return(
                          <tr key={i}>
                            <td className="num_fecha">
                              {window.abmeses[miFecha.getMonth()]}
                              <br/>                         
                              {item.per_rfc.slice(8, 10)}
                            </td>
                            <td>
                              <h14>{item.completo}</h14>
                              {
                                item.comi_area ? 
                                  <h15>{window._my_organismos.filter(p => p.id===item.comi_area)[0].nombre}</h15>
                                : item.per_c_area ?
                                  <h15>{window._my_organismos.filter(p => p.id===item.per_c_area)[0].nombre}</h15>
                                : null
                              }
                            </td>
                          </tr>
                        )
                      })
                    }
                  </table>
                  <div className="ver_todos" onClick={() => {
                    setModal({
                      ver: true,
                      tipo: "cumples_mes",
                      titulo: `Cumpleaños de ${window.meses[window.date.getMonth()]}`,
                      arreglo: arreglo_sort,
                    })
                  }}>
                    Cumpleaños del mes
                  </div>
                </div>
              </div>
              {
                festivos.length>0 ?
                  <div className={cumple.length>0 ? "cumple_mes" : "cumple_mes_dos"} data-aos="fade-left">
                    <div className="titulos_intranet">
                      Próximos festivos
                    </div>
                    <div className="prox_cumpleanos">
                      <table className="carrillo">
                        {
                          festivos.map((item, i) => {
                            let miFecha = new Date(`${item.fecha}T00:00`)
                            return(
                              <tr key={i}>
                                <td className={item.fecha===window.ndate ? 'num_fecha_hoy' : 'num_fecha'}>
                                  {window.abmeses[miFecha.getMonth()]}
                                  <br/>                         
                                  {item.fecha.slice(8, 10)}
                                </td>
                                <td>
                                  {item.fecha===window.ndate ? <h16>{item.festejo}</h16> : <h14>{item.festejo}</h14>}
                                  {
                                    item.oficial>0 ? 
                                      <h15>Oficial</h15>
                                    : 
                                      <h15>No oficial</h15>
                                  }
                                </td>
                              </tr>
                            )
                          })
                        }
                      </table>
                      <div className="ver_todos" onClick={() => {
                        setModal({
                          ver: true,
                          tipo: "festivos_mes",
                          titulo: `Días festivos de ${window.meses[window.date.getMonth()]}`,
                          arreglo: festivos,
                        })
                      }}>
                        Festivos del mes
                      </div>
                    </div>
                  </div>
                :
                  null
              }
            </div>
          :
            null
        }
        <Element name="organismos"></Element>
        <div className="intranet_dos intranet_mtop">
          {
            window._my_organismos.filter(p => p.tipo!=='SEDECO' && p.status===0).length>0 ?
              <div className="de_intranet_dos" data-aos="fade-right">
                <div className="titulos_intranet">
                  Organismos
                </div>
                <div className="organismos">
                  {
                    window._my_organismos.filter(p => p.tipo!=='SEDECO').map((item, i) =>
                      <div key={i} onClick={() => {
                        setModal({
                          ver: true,
                          tipo: "organismos",
                          titulo: item.nombre,
                        })
                        setModalID(item.id)
                      }}>
                        <img src="img/organismos.svg" alt="Organismos"/>
                        {item.nombre}
                      </div>
                    )
                  }
                </div>
              </div>
            :
              null
          }
          {
            window._my_comites.length>0 || window._my_normateca.length>0 ?
              <div className="de_intranet_dos" data-aos="fade-left">
                {
                  window._my_comites.length>0 ?
                    <div>
                      <div className="titulos_intranet">
                        Comités
                      </div>
                      <div className="organismos">
                        {
                          window._my_comites.map((item, i) =>
                            <div key={i} onClick={() => {
                              setModal({
                                ver: true,
                                tipo: "comites",
                                titulo: item.nombre,
                              })
                              setModalID(item.id)
                            }}>
                              <img src="img/comites.svg" alt="Comités"/>
                              {item.nombre}
                            </div>
                          )
                        }
                      </div>
                    </div>
                  :
                    null
                }
                {
                  window._my_normateca.length>0 ?
                    <div>
                      <div className="titulos_intranet">
                        Normateca
                      </div>
                      <div className="organismos">
                        {
                          window._my_normateca.slice(0, 3).map((item, i) =>
                            <div key={i} onClick={() => {
                              setModal({
                                ver: true,
                                tipo: "normateca",
                                titulo: item.nombre,
                              })
                              setModalID(item.id)
                            }}>
                              <img src="img/concesiones.png" alt="Normateca"/>
                              {item.nombre}
                            </div>
                          )
                        }
                        <div className="ver_todos" onClick={() => {
                          setModal({
                            ver: true,
                            tipo: "normateca",
                            titulo: "Normateca",
                          })
                          setModalID(0)
                        }}>
                          Ver más
                        </div>
                      </div>
                    </div>
                  :
                    null
                }
              </div>
            :
              null
          }
        </div>
        {
          window._my_apps.length>0 ?
            <>
              <Element name="aplicaciones"></Element>
              <div className="titulos_intranet intranet_mtop">
                Aplicaciones
              </div>
              <div className="aplicaciones">
                {
                  window._my_apps.map((item, i) => 
                    <a href={item.link} className={i>0 && (i%5)===0 ? null : "app_margen"} target="new" key={i} data-aos="fade-up">
                      <img src={item.logo} className="hvr-push" title={item.titulo} alt={item.titulo}/>
                    </a>
                  )
                }
              </div>
              <div id="cl"></div>
            </>
          :
            null
        }
        <div className="intranet_dos intranet_mtop_dos">
          <div className="de_intranet_dos" data-aos="fade-right">
            <div className="titulos_intranet">
              Misión
            </div>
            <div dangerouslySetInnerHTML={{ __html: window._mision }} className="texto_mision"/>
            <div className="fuente_mision">Fuente: {window._fmision}</div>
          </div>
          <div className="de_intranet_dos" data-aos="fade-left">
            <div className="titulos_intranet">
              Visión
            </div>
            <div dangerouslySetInnerHTML={{ __html: window._vision }} className="texto_mision"/>
            <div className="fuente_mision">Fuente: {window._fvision}</div>
          </div>
        </div>
        <Element name="utilidades"></Element>
        <div className="intranet_utilidades intranet_mtop">
          <div className="utilidades" data-aos="fade-up">
            <div className="titulos_intranet">
              Directorio
            </div>
            <div className="utilidades_estilo directorio" onClick={() => {
              setDirectorio({
                ver: true,
                tipo: "directorio",
                titulo: "Directorio",
              })
            }}>
              <div>
                <img src="img/directorio.svg" alt="Directorio"/>
                <div>Directorio SEDECO</div>
              </div>
            </div>
          </div>
          <div className="utilidades" data-aos="fade-up">
            <div className="titulos_intranet">
              Soporte
            </div>
            <div className="utilidades_estilo soporte" onClick={() => {
              setDirectorio({
                ver: true,
                tipo: "directorio",
                titulo: "Directorio",
              })
            }}>
              <div>
                <img src="img/soporte.svg" alt="Soporte"/>
                <div>Soporte informático</div>
              </div>
            </div>
          </div>
          <div className="utilidades" data-aos="fade-up">
            <div className="titulos_intranet">
              Buzón
            </div>
            <div className="utilidades_estilo buzon" onClick={() => {
              setModal({
                ver: true,
                tipo: "buzon",
                titulo: "Buzón",
              })
            }}>
              <div>
                <img src="img/buzon.svg" alt="Buzón"/>
                <div>Tu opinión es valiosa</div>
              </div>
            </div>
          </div>
          {
            localStorage._ROL==='ADMIN' || localStorage._ROL==='VIGILANTE' || localStorage._ROL==='RECEPCION' ?
              <div className="utilidades" data-aos="fade-up">
                <div className="titulos_intranet">
                  Visitantes
                </div>
                <div className="utilidades_estilo visitantes" onClick={() => {
                  setModal({
                    ver: true,
                    tipo: "visitantes",
                    titulo: "Visitantes",
                  })
                }}>
                  <div>
                    <img src="img/visitantes.svg" alt="Visitantes"/>
                    <div>Registro y consulta</div>
                  </div>
                </div>
              </div>
            :
              <div className="utilidades">
              </div>
          }
        </div>
      </div>
      <Modales modal={modal} setModal={setModal} modalID={modalID} setModalID={setModalID}/>
      <Directorio directorio={directorio} setDirectorio={setDirectorio}/>
    </div>
  );
}

export default Inicio;
