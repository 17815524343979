import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import splash from './splash'
import Axios from 'axios';
import alerta from './alerta'
import { useSelector } from 'react-redux'

function Directorio(params) {
  const personalRedux = useSelector((state) => state.personal.value)
  const [per_nombre, set_per_nombre] = useState([])
  const [per_c_area, set_per_c_area] = useState([])
  const [datosUsuario, setDatosUsuario] = useState(0)

  useEffect(() => {
    //return () => {
      splash()
    //}
  }, []);

  const limpiar = () => {
    set_per_nombre([])
    set_per_c_area([])
    setDatosUsuario(0)
  }

  let condicion_consulta = `p.per_status===3`
  if(per_nombre.length>0){
    condicion_consulta += `&& (`
    per_nombre.map((item, i) => { //eslint-disable-line
      let or = " || "
      if(i===0){
        or = ""
      }
      //condicion_consulta += `${or}p.per_nombre.filter(t => t.per_nombre==='${item}').length>0`
      condicion_consulta += `${or}p.completo==='${item.completo}'`
    })
    condicion_consulta += `)`
  }
  if(per_c_area.length>0){
    condicion_consulta += `&& (`
    per_c_area.map((item, i) => { //eslint-disable-line
      let or = " || "
      if(i===0){
        or = ""
      }
      condicion_consulta += `${or} (p.comi_area ? p.comi_area===${item.id} : p.per_c_area===${item.id})`
    })
    condicion_consulta += `)`
  }
  let lista_filtrada = personalRedux.filter(p => eval(condicion_consulta)) //eslint-disable-line

  return (
    <div>
      <Modal
        show={params.directorio.ver}
        onHide={() => {
          params.setDirectorio({
            ver: false,
            tipo: "",
            titulo: "",
          })
          limpiar()
        }}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h8>{params.directorio.titulo}</h8>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal_directorio">
            <Form className={datosUsuario>0 ? 'formulario_directorio_ch' : 'formulario_directorio'}>
              <h13>Realiza una consulta:</h13>
              <Form.Group>
                <Form.Label>Nombre:</Form.Label>
                <Typeahead
                  id="basic-typeahead-multiple"
                  multiple
                  labelKey="completo"
                  onChange={set_per_nombre}
                  options={personalRedux.filter(p => p.per_status===3)}
                  selected={per_nombre}
                  emptyLabel="No hay resultados."
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Área:</Form.Label>
                <Typeahead
                  id="basic-typeahead-multiple"
                  multiple
                  labelKey="nombre"
                  onChange={set_per_c_area}
                  options={window._my_organismos.filter(p => p.status===0)}
                  selected={per_c_area}
                  emptyLabel="No hay resultados."
                />
              </Form.Group>
              <div className="espacio_input"/>
              <Button variant="secondary" className="espacio_boton" onClick={limpiar}>
                Limpiar
              </Button>
              {
                lista_filtrada.length>0 ?
                  <Button variant="success" onClick={() => setDatosUsuario(1)}>
                    Consultar ({lista_filtrada.length})
                  </Button>
                :
                  <Button variant="warning" onClick={() => setDatosUsuario(0)}>
                    No hay información con esos criterios
                  </Button>
              }
            </Form>
          
            {
              datosUsuario>0 && lista_filtrada.length>0 ?
                <div className="div_directorio">
                  <table className="carrillo">
                    {
                      lista_filtrada.map((item, i) => 
                        <tr key={i}>
                          <td className="foto_directorio">
                            <img src={item.per_foto ? `${window.ws}documentos/fotos/F_${item.per_id}.${item.per_foto}` : `img/ico.svg`} alt="Directorio"/>
                          </td>
                          <td className="flex_directorio">
                            <div><img src="img/dir_1.svg" alt="Directorio"/>{item.completo}</div>
                            {
                              item.comi_area ? 
                                <div><img src="img/dir_2.svg" alt="Directorio"/>{window._my_organismos.filter(p => p.id===item.comi_area)[0].nombre}</div>
                              : item.per_c_area ?
                                <div><img src="img/dir_2.svg" alt="Directorio"/>{window._my_organismos.filter(p => p.id===item.per_c_area)[0].nombre}</div>
                              : null
                            }
                            {
                              item.per_tel_oficina ? 
                                <div><img src="img/dir_4.svg" alt="Directorio"/><div>{item.per_tel_oficina}{item.per_extension ? ` extensión ${item.per_extension}` : null}</div></div>
                              : null
                            }
                            {
                              item.per_institucional ? 
                                <div><img src="img/dir_5.svg" alt="Directorio"/>{item.per_institucional}</div>
                              : null
                            }
                            {
                              item.per_correo ? 
                                <div><img src="img/dir_5.svg" alt="Directorio"/>{item.per_correo}</div>
                              : null
                            }
                          </td>
                        </tr>
                      )
                    }
                  </table>
                  <div id="cl"></div>
                </div>
              :
                null
            }
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Directorio;