import { memo } from 'react';
import { Link } from 'react-router-dom'

function Pie() {
  return (
    <div className="pie">
      <div className="max_width padding_width">
        <div className="de_pie">
          <a href={window.web} target="new"><img src="img/economia.svg" className="saturate" alt="SEDECO" title="SEDECO"/></a>
          <div></div>
          <div className="pie_aviso">
            Intranet v2.0.{window._my_version} © {window.ano}<br/>
            <Link to="aviso" className="a_pie">Aviso de privacidad</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(Pie);
