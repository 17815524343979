import { useEffect, useState, memo } from 'react';
import { Link } from 'react-router-dom'
import { Element, Link as Ir } from 'react-scroll';
import Axios from 'axios';

import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { miMenu, } from './../redux/menuRedux'
import { miStyle, } from './../redux/styleRedux'
import { miPersonal } from './../redux/personalRedux'
import cerrarSesion from './ultilidades/cerrar'

function Header() {
  const [scroll, setScroll] = useState(0);
  const menuRedux = useSelector((state) => state.menu.value)
  const styleRedux = useSelector((state) => state.style.value)
  const dispatch = useDispatch()
  const [foto, set_foto] = useState("");

  const selec = () => {
    window.H5_loading.show();
    var pasar = new URLSearchParams();
    pasar.append('_USUARIO', localStorage.usuario);
    pasar.append('_ROL', localStorage._ROL);
    pasar.append('_Activa', localStorage._Activa);

    Axios.post(`${window.ws}wsSelectRH.php`, pasar)
      .then(response => {
        if(response.data.elError===1){
          //alerta('succes', 'lol'+response.data.lista_array.length)
          dispatch(miPersonal(response.data.lista_array))

          let filtrado = response.data.lista_array.filter(p => p.per_rfc===localStorage.usuario)
          if(filtrado.length>0){
            if(filtrado[0].per_foto){
              set_foto("F_"+filtrado[0].per_id+"."+filtrado[0].per_foto)
            }
          }
        }else{
          //alerta('error', '1lol'+response.data.mensaje)
          console.log(response.data.mensaje)
        }
      })
      .catch(error => {
        //alerta('error', '1lol'+error)
        console.log(error)
      })
      .then(function() {
        window.H5_loading.hide();
      })
  }

  //const useComponentDidMount = cb => useEffect(cb, []);
  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      setScroll(window.scrollY)
    });
  });

  useEffect(() => {
    if(foto===""){
      selec()
    }
  }, [foto]);

  return (
    <div>
      <Element name="inicio"></Element>
      <div className="icono_menu" onClick={() => dispatch(miStyle('ver_menu'))}><img src="img/menu.svg" title="Menú" alt="Menú"/></div>
      <div className="cerrar_menu" id={styleRedux} onClick={() => dispatch(miStyle('no_ver_menu'))}></div>
      <div className="header" id={window.screen.width>780 ? null : styleRedux}>
        <div className="max_width">
          <div className="header_flex">
            <div>
              <Link to="inicio" className="intranet">
                INTRA<h11>NET</h11>
              </Link>
              <div className="titulo_menu">
                {
                  menuRedux==='cuenta' ?
                    "Mi cuenta"
                  : menuRedux==='humanos' ?
                    "Recursos humanos"
                  : 
                    menuRedux
                }
              </div>
            </div>
            {
              menuRedux==='inicio' || menuRedux==='' ?
                <div className="header_menu">
                  <Ir to="redes" className="mi_enlace">Redes</Ir>
                  {window._my_organismos.length>0 ? <Ir to="organismos" className="mi_enlace">Organismos</Ir> : null}
                  {window.window._my_apps.length>0 ? <Ir to="redes" className="mi_enlace">Aplicaciones</Ir> : null}
                  <Ir to="utilidades" className="mi_enlace">Utilidades</Ir>
                </div>
              :
                null
            }
            <Link to="cuenta" className="header_foto" onClick={() => dispatch(miMenu('cuenta'))}>
              <img src={foto ? `${window.ws}documentos/fotos/${foto}` : `img/ico.svg`} title="Fotografía" alt="Fotografía"/>
              <div>
                {localStorage._Nombre}
                <br/>
                <h10>{localStorage.usuario}</h10>
              </div>
            </Link>
          </div>
        </div>
      </div>
      {
        scroll>100 ?
          <Ir to="inicio" className="subir">➤</Ir>
        :
          null
      }
    </div>
  );
}

export default memo(Header);
