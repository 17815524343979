import { useState, memo  } from 'react';
import { Link/*, useHistory*/ } from 'react-router-dom'

import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { miMenu, } from './../redux/menuRedux'
import cerrarSesion from './ultilidades/cerrar'

function Menu() {
  //const history = useHistory()
  const styleRedux = useSelector((state) => state.style.value)
  const dispatch = useDispatch()

  const [menu, set_menu] = useState({
    display: 'none',
    menu: 0,
  });

  const funcionMenu = (display, menu) => {
    set_menu(
      {
        display: display,
        menu: menu,
      }
    )
  }

  return (
    <div className="quitar_menu_izquierdo" id={window.screen.width>780 ? null : styleRedux}>
      <div className="menu_izquierdo">
        
      </div>
      <div className="menu_izquierdo_menu">
        <div>
          <Link to="inicio" className="menu_iconos" onClick={() => dispatch(miMenu('inicio'))} onMouseOver={() => funcionMenu('block', 1)} onMouseOut={() => funcionMenu('none', 0)}>
            <img src="img/m_home.svg" title="Inicio" alt="Inicio"/>
            <div id={menu.menu===1 ? menu.display : 'none'}>
              Inicio
            </div>
          </Link>
          {
            localStorage._ROL==='ADMIN' || localStorage._ROL==='RH' ?
              <Link to="humanos" className="menu_iconos" onClick={() => dispatch(miMenu('humanos'))} onMouseOver={() => funcionMenu('block', 5)} onMouseOut={() => funcionMenu('none', 0)}>
                <img src="img/m_usuario.svg" title="Recursos humanos" alt="Recursos humanos"/>
                <div id={menu.menu===5 ? menu.display : 'none'}>
                  Recursos humanos
                </div>
              </Link>
            :
              null
          }
          <Link to="cuenta" className="menu_iconos" onClick={() => dispatch(miMenu('cuenta'))} onMouseOver={() => funcionMenu('block', 2)} onMouseOut={() => funcionMenu('none', 0)}>
            <img src="img/m_configurar.svg" title="Mi cuenta" alt="Mi cuenta"/>
            <div id={menu.menu===2 ? menu.display : 'none'}>
              Mi cuenta
            </div>
          </Link>
          <a href="https://mail.google.com/mail/u/0/#inbox" target="new" className="menu_iconos" onMouseOver={() => funcionMenu('block', 3)} onMouseOut={() => funcionMenu('none', 0)}>
            <img src="img/m_correo.svg" title="Correo" alt="Correo"/>
            <div id={menu.menu===3 ? menu.display : 'none'}>
              Correo
            </div>
          </a>
          <div className="menu_iconos" onClick={() => cerrarSesion()} onMouseOver={() => funcionMenu('block', 4)} onMouseOut={() => funcionMenu('none', 0)}>
            <img src="img/m_salir.svg" title="Salir" alt="Salir"/>
            <div id={menu.menu===4 ? menu.display : 'none'}>
              Salir
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default memo(Menu);