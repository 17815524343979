import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Menu from './components/Menu'
import Header from './components/Header'
import Pie from './components/Pie'

import Login from './components/Login'
import Inicio from './components/Inicio'
import Cuenta from './components/Cuenta'
import Humanos from './components/Humanos'

function App() {
  return (
    <Router>
      {
        localStorage._ROL ? 
          <div className="flex_contenido">
            <Menu />
            <div className="contenido">
              <Header />
              <Switch>
                <Route exact path="/" component={Inicio} />
                <Route path={window.path+"/inicio"} component={Inicio} />
                <Route path={window.path+"/cuenta"} component={Cuenta} />
                <Route path={window.path+"/humanos"} component={Humanos} />
                <Route component={Inicio} />
              </Switch>
              <Pie />
            </div>
          </div>
        :
          <Switch>
            <Route exact path="/" component={Login} />
            <Route component={Login} />
          </Switch>
      } 
    </Router>
  );
}

export default App;
