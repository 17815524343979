import { createSlice } from '@reduxjs/toolkit'

let link = window.location.href.split('/')[4]
if(window.location.href.split('/')[2]==='localhost:3000'){
  link = window.location.href.split('/')[3]
}

export const menuReducer = createSlice({
  name: 'menu',
  initialState: {
    value: link,
  },
  reducers: {
    miMenu: (state, action) => {
      state.value = action.payload
    },
  },
})

export const { miMenu, } = menuReducer.actions
export default menuReducer.reducer