import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Axios from 'axios';
import splash from './ultilidades/splash'
import Cuenta from './Cuenta'
import CloseButton from 'react-bootstrap/CloseButton';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Element, Link as Ir } from 'react-scroll';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Swal from 'sweetalert2'
import alerta from './ultilidades/alerta'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { miPersonal, } from './../redux/personalRedux'

function Humanos() {
  const personalRedux = useSelector((state) => state.personal.value)
  const dispatch = useDispatch()
  const [modal, setModal] = useState(false)
  const [tipoConsulta, setTipoConsulta] = useState(0)
  const [inicial, setInicial] = useState(0)
  const [mostrar, setMostrar] = useState(25)
  const [cantidad, setCantidad] = useState(25)

  const [recursos, setRecursos] = useState(0)
  const [usuario, setUsuario] = useState(0)

  const [per_fecha, set_per_fecha] = useState('')
  const [per_nombre, set_per_nombre] = useState([])
  const [per_c_area, set_per_c_area] = useState([])
  const [per_c_nivel, set_per_c_nivel] = useState([])
  const [per_rfc, set_per_rfc] = useState('')
  const [per_sexo, set_per_sexo] = useState('')
  const [per_estado, set_per_estado] = useState('')
  const [per_municipio, set_per_municipio] = useState('')
  const [per_rol, set_per_rol] = useState('')
  const [per_status, set_per_status] = useState('')

  //const [listaPersonal, setListaPersonal] = useState([])
  const [datosUsuario, setDatosUsuario] = useState([])
  const selec = () => {
    window.H5_loading.show();
    var pasar = new URLSearchParams();
    pasar.append('_USUARIO', localStorage.usuario);
    pasar.append('_ROL', localStorage._ROL);
    pasar.append('_Activa', localStorage._Activa);

    Axios.post(`${window.ws}wsSelectRH.php`, pasar)
      .then(response => {
        if(response.data.elError===1){
          dispatch(miPersonal(response.data.lista_array))

          let lista_filtrada = response.data.lista_array.filter(p => eval(condicion_consulta)) //eslint-disable-line
          setDatosUsuario(lista_filtrada)
          //alerta('success', 'si')
        }else{
          //alerta('error', response.data.mensaje)
          console.log(response.data.mensaje)
        }
      })
      .catch(error => {
        //alerta('error', ''+error)
        console.log(error)
      })
      .then(function() {
        window.H5_loading.hide();
      })
  }

  let condicion_consulta = `p.per_id`
  if(per_nombre.length>0){
    condicion_consulta += `&& (`
    per_nombre.map((item, i) => { //eslint-disable-line
      let or = " || "
      if(i===0){
        or = ""
      }
      //condicion_consulta += `${or}p.per_nombre.filter(t => t.per_nombre==='${item}').length>0`
      condicion_consulta += `${or}p.completo==='${item.completo}'`
    })
    condicion_consulta += `)`
  }
  if(per_c_area.length>0){
    condicion_consulta += `&& (`
    per_c_area.map((item, i) => { //eslint-disable-line
      let or = " || "
      if(i===0){
        or = ""
      }
      condicion_consulta += `${or}p.per_c_area===${item.id} || p.comi_area===${item.id}`
    })
    condicion_consulta += `)`
  }
  if(per_c_nivel.length>0){
    condicion_consulta += `&& (`
    per_c_nivel.map((item, i) => { //eslint-disable-line
      let or = " || "
      if(i===0){
        or = ""
      }
      condicion_consulta += `${or}p.per_c_nivel===${item.id}`
    })
    condicion_consulta += `)`
  }
  if(per_sexo){
    condicion_consulta += `&& p.per_sexo==='${per_sexo}'`
  }
  if(per_rfc){
    condicion_consulta += `&& p.per_rfc==='${per_rfc}'`
  }
  if(per_estado){
    condicion_consulta += `&& p.per_estado==='${per_estado}'`
  }
  if(per_municipio){
    condicion_consulta += `&& p.per_municipio==='${per_municipio}'`
  }
  if(per_rol){
    condicion_consulta += `&& p.per_rol==='${per_rol}'`
  }
  if(per_status){
    condicion_consulta += `&& p.per_status===${per_status}`
  }
  if(per_fecha){
    let fecha_nac = per_fecha.split("-")[0].slice(2, 4)+per_fecha.split("-")[1]
    condicion_consulta += `&& p.per_rfc.slice(4, 8)==='${fecha_nac}'`
  }
  let lista_filtrada = personalRedux.filter(p => eval(condicion_consulta)) //eslint-disable-line

  let initialized = false
  useEffect(() => {
    if (!initialized) {
      initialized = true
      splash()
    }
  }, []);

  const limpiar = () => {
    set_per_nombre([])
    set_per_c_area([])
    set_per_c_nivel([])
    set_per_rfc('')
    set_per_sexo('')
    set_per_estado('')
    set_per_municipio('')
    set_per_rol('')
    set_per_status('')
    setDatosUsuario([])

    setInicial(0)
    setMostrar(25)
    setCantidad(25)
  }

  return (
    <div>
      <div className="contenido_general">
        <div className="max_width padding_width">
          <div className="titulos_intranet">
            Personal
          </div>
          <Form>
            <div className="flex_act_nueva">
              <div className="flex_nueva" onClick={() => {
                setModal(true)
                setRecursos(1)
                setUsuario(0)
              }}>
                <div className="nueva">+</div>
                <div className="l_nueva">Registrar empleado</div>
              </div>
            </div>
            <h13>Realiza una consulta:</h13>
            <Form.Group>
              <div className="flex_botones">
                <div id={tipoConsulta===0 ? 'boton_marca' : null} onClick={() => setTipoConsulta(0)}>Consulta rápida</div>
                <div id={tipoConsulta===1 ? 'boton_marca' : null} onClick={() => setTipoConsulta(1)}>Consulta avanzada</div>
              </div>
            </Form.Group>
            <div className="espacio_input"/>
            <div className="formulario_dos sin_tanto_espacio">
              <div className="de_formulario_dos">
                <Form.Group>
                  <Form.Label>Nombre:</Form.Label>
                  <Typeahead
                    id="basic-typeahead-multiple"
                    multiple
                    labelKey="completo"
                    onChange={set_per_nombre}
                    options={personalRedux}
                    selected={per_nombre}
                    emptyLabel="No hay resultados."
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Área:</Form.Label>
                  <Typeahead
                    id="basic-typeahead-multiple"
                    multiple
                    labelKey="nombre"
                    onChange={set_per_c_area}
                    options={window._my_organismos.filter(p => p.status===0)}
                    selected={per_c_area}
                    emptyLabel="No hay resultados."
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Nivel:</Form.Label>
                  <Typeahead
                    id="basic-typeahead-multiple"
                    multiple
                    labelKey={option => `${option.cargo} - ${option.nombramiento} - ${option.nivel}`}
                    onChange={set_per_c_nivel}
                    options={window._my_puestos}
                    selected={per_c_nivel}
                    emptyLabel="No hay resultados."
                  />
                </Form.Group>
                {
                  localStorage._ROL==='ADMIN' ?
                    <Form.Group>
                      <Form.Label>Rol del sistema:</Form.Label>
                      <Form.Select onChange={(event) => set_per_rol(event.target.value)}>
                        <option selected={per_rol==="" ? true : false}></option>
                        <option selected={per_rol==="ADMIN" ? true : false} value="ADMIN">Administrador (ADMIN)</option>
                        <option selected={per_rol==="RH" ? true : false} value="RH">Recursos humanos (RH)</option>
                        <option selected={per_rol==="RM" ? true : false} value="RM">Recursos materiales (RM)</option>
                        <option selected={per_rol==="COM" ? true : false} value="COM">Comunicación (COM)</option>
                        <option selected={per_rol==="USER" ? true : false} value="USER">Usuario normal (USER)</option>
                      </Form.Select>
                    </Form.Group>
                  :
                    null
                }
              </div>
              <div className="de_formulario_dos">
                {
                  tipoConsulta>0 ?
                    <div>
                      <Form.Group>
                        <Form.Label>Sexo:</Form.Label>
                        <div className="flex_botones">
                          <div id={per_sexo==='Hombre' ? 'boton_marca' : null} onClick={() => set_per_sexo('Hombre')}>Hombre</div>
                          <div id={per_sexo==='Mujer' ? 'boton_marca' : null} onClick={() => set_per_sexo('Mujer')}>Mujer</div>
                        </div>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Fecha de nacimiento:</Form.Label>
                        <Form.Control type="month" placeholder="aaaa-mm" value={per_fecha} onChange={(event) => {set_per_fecha(event.target.value)}}/>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Registro Federal de Contribuyentes (RFC):</Form.Label>
                        <Form.Control type="text" value={per_rfc} onChange={(event) => {set_per_rfc(event.target.value)}}/>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Estado:</Form.Label>
                        <Form.Select onChange={(event) => set_per_estado(event.target.value)}>
                          <option selected={per_estado==="" ? true : false}></option>
                          {
                            window._my_estado.map((item, i) =>
                              <option key={i} selected={per_estado===item.estado ? true : false}>{item.estado}</option>
                            )
                          }
                        </Form.Select>
                      </Form.Group>
                      {
                        per_estado ?
                          <Form.Group>
                            <Form.Label>Municipio:</Form.Label>
                            <Form.Select onChange={(event) => set_per_municipio(event.target.value)}>
                              <option selected={per_municipio==="" ? true : false}></option>
                              {
                                window._my_estado.filter(p => p.estado===per_estado)[0].municipios.map((item, i) =>
                                  <option key={i} selected={per_municipio===item ? true : false}>{item}</option>
                                )
                              }
                            </Form.Select>
                          </Form.Group>
                        :
                          null
                      }
                      <Form.Group>
                        <Form.Label>Estado de la cuenta:</Form.Label>
                        <Form.Select onChange={(event) => set_per_status(event.target.value)}>
                          <option selected={per_status==="" ? true : false}></option>
                          <option selected={per_status===1 ? true : false} value={1}>Inactivo</option>
                          <option selected={per_status===3 ? true : false} value={3}>Activo</option>
                          <option selected={per_status===2 ? true : false} value={2}>Baja</option>
                        </Form.Select>
                      </Form.Group>
                    </div>
                  :
                    null
                }
              </div>
            </div>
            <div className="espacio_input"/>
            <Button variant="secondary" className="espacio_boton" onClick={limpiar}>
              Limpiar
            </Button>
            {
              lista_filtrada.length>0 ?
                <Ir to="recorrer" activeClass="active" spy={true} smooth={true} duration={1000}>
                  <Button variant="success" onClick={() => setDatosUsuario(lista_filtrada)}>
                    Consultar ({lista_filtrada.length})
                  </Button>
                </Ir>
              :
                <Button variant="warning" onClick={() => setDatosUsuario([])}>
                  No hay información con esos criterios
                </Button>
            }
          </Form>
          <Element name="recorrer"></Element>
          {
            datosUsuario.length>0 ? 
              <div>
                <div className="linea_consulta"/>
                <div className="excel">
                  <ReactHTMLTableToExcel
                    className="boton_excel"
                    table="table-to-xls"
                    filename="Reporte_personal"
                    buttonText={<img src="img/ico_excel.svg" alt="Excel" className="hvr-push" title="Descargar Excel"/>}
                  />
                </div>
                <div className="flex_resultados">
                  Resultados encontrados: {datosUsuario.length}
                  {
                    datosUsuario.length>25 ? 
                      <select name="mostrar" onChange={(event) => {
                        setInicial(0)
                        setMostrar(event.target.value)
                        setCantidad(event.target.value)
                      }}>
                        <option value={25} selected={cantidad===25 ? true : false}>25</option>
                        {datosUsuario.length>=50 ? <option value={50} selected={cantidad===50 ? true : false}>50</option> : null}
                        {datosUsuario.length>=100 ? <option value={100} selected={cantidad===100 ? true : false}>100</option> : null}
                        {datosUsuario.length>=500 ? <option value={500} selected={cantidad===500 ? true : false}>500</option> : null}
                        {datosUsuario.length>=1000 ? <option value={1000} selected={cantidad===1000 ? true : false}>1000</option> : null}
                        <option value={99999999999999999999} selected={cantidad===99999999999999999999 ? true : false}>Todos</option>
                      </select>
                    :
                      null
                  }
                </div>
                <div className="tabla_responsiva">
                  <table className="carrillo" cellspacing="3">
                    <tr>
                      <th>#</th>
                      <th>Nombre</th>
                      <th>RFC</th>
                      <th>Área</th>
                      <th>Nivel</th>
                      {
                        localStorage._ROL==='ADMIN' ?
                          <th>Rol</th>
                        :
                          null
                      }
                      <th>Estatus</th>
                      <th></th>
                    </tr>
                    {
                      datosUsuario.slice(inicial, mostrar).map((item, i) =>
                        <tr key={i}>
                          <td>{i+1}</td>
                          <td>{item.completo}</td>
                          <td>{item.per_rfc}</td>
                          <td>
                            {item.per_c_area ? window._my_organismos.filter(p => p.id===item.per_c_area)[0].nombre : null}
                            {item.comi_area ? <div>({window._my_organismos.filter(p => p.id===item.comi_area)[0].nombre})</div> : null}
                          </td>
                          <td>{item.per_c_nivel ? window._my_puestos.filter(p => p.id===item.per_c_nivel)[0].cargo : null}</td>
                          {
                            localStorage._ROL==='ADMIN' ?
                              <td>{item.per_rol}</td>
                            :
                              null
                          }
                          <td>
                            {
                              item.per_status===1 ?
                                <div className="color_rojo">Baja</div>
                              : item.per_status===2 ?
                                <div className="color_naranja">Inactivo</div>
                              :
                                <div className="color_verde">Activo</div>
                            }
                          </td>
                          <td>
                            <img src="img/editar.png" alt="Editar" title="Editar" className="hvr-push icon_form" onClick={() => {
                              setModal(true)
                              setRecursos(0)
                              setUsuario(item.per_id)
                            }}/>
                            {item.per_foto ? <a href={`${window.ws}documentos/fotos/F_${item.per_id}.${item.per_foto}`} target="new"><img src="img/foto.png" alt="Fotografía" title="Fotografía" className="hvr-push icon_form"/></a> : null}
                            {
                              item.per_status>1 ? 
                                <img src="img/eliminar.png" alt="Baja" title="Baja" className="hvr-push icon_form" onClick={() => {
                                  Swal.fire({
                                    title: '¿Deseas dar de baja al empleado?',
                                    //text: "You won't be able to revert this!",
                                    type: 'warning',
                                    showCancelButton: true,
                                    confirmButtonColor: '#3085d6',
                                    cancelButtonColor: '#d33',
                                    confirmButtonText: 'Sí, de acuerdo!'
                                  }).then((result) => {
                                    if(result.value){
                                      window.H5_loading.show();
                                      var pasar = new URLSearchParams();
                                      pasar.append('_USUARIO', localStorage.usuario);
                                      pasar.append('_ROL', localStorage._ROL);
                                      pasar.append('_Activa', localStorage._Activa);
                                      
                                      pasar.append('que', 'borrar');
                                      pasar.append('per_id', item.per_id);
                                      Axios.post(`${window.ws}wsEmpleados.php`, pasar)
                                        .then(response => {
                                          if(response.data.elError===1){
                                            alerta('success',''+response.data.mensaje);
                                            selec()
                                          }else{
                                            alerta('error',''+response.data.mensaje);
                                          }
                                        })
                                        .catch(error => {
                                          console.log(error)
                                        })
                                        .then(function() {
                                          window.H5_loading.hide();
                                        })
                                    }
                                  })
                                }}/>
                              :
                                null
                            }
                          </td>
                        </tr>
                      )
                    }
                  </table>
                </div>
                <div className="flex_siguiente">
                  <div>{inicial===0 ? null : <Button variant="dark" onClick={() => {
                    setMostrar(mostrar-cantidad)
                    setInicial(inicial-cantidad)
                  }}>🡨</Button>}</div>
                  <div>{mostrar>=datosUsuario.length ? null : <Button variant="dark" onClick={() => {
                    setMostrar(mostrar+cantidad)
                    setInicial(inicial+cantidad)
                  }}>🡪</Button>}</div>
                </div>
                <table id="table-to-xls" style={{display:"none"}}>
                    <tr>
                      <th>#</th>
                      <th>Nombre</th>
                      <th>RFC</th>
                      <th>Área</th>
                      <th>Nivel</th>
                      {
                        localStorage._ROL==='ADMIN' ?
                          <th>Rol</th>
                        :
                          null
                      }
                      <th>Estatus</th>
                      <th></th>
                    </tr>
                    {
                      datosUsuario.map((item, i) =>
                        <tr key={i}>
                          <td>{i+1}</td>
                          <td>{item.completo}</td>
                          <td>{item.per_rfc}</td>
                          <td>
                            {item.per_c_area ? window._my_organismos.filter(p => p.id===item.per_c_area)[0].nombre : null}
                            {item.comi_area ? <div>({window._my_organismos.filter(p => p.id===item.comi_area)[0].nombre})</div> : null}
                          </td>
                          <td>{item.per_c_nivel ? window._my_puestos.filter(p => p.id===item.per_c_nivel)[0].cargo : null}</td>
                          {
                            localStorage._ROL==='ADMIN' ?
                              <td>{item.per_rol}</td>
                            :
                              null
                          }
                          <td>
                            {
                              item.per_status===1 ?
                                "Baja"
                              : item.per_status===2 ?
                                "Inactivo"
                              :
                                "Activo"
                            }
                          </td>
                        </tr>
                      )
                    }
                  </table>
              </div>
            :
              null
          }
        </div>
        <Modal
          show={modal}
          size="lg"
          className="modal_registro"
          centered
          onHide={() => {
            if(usuario>0){
              setModal(false)
            }
          }}
        >
          <Modal.Header>
            <Modal.Title>
              {usuario>0 ? "Editar empleado" : "Registrar empleado"}
              <CloseButton aria-label="Hide" onClick={() => setModal(false)}/>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Cuenta recursos={recursos} usuario={usuario} modal={modal} setModal={setModal} setDatosUsuario={setDatosUsuario} condicion={condicion_consulta}/>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default Humanos;
