import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Accordion from 'react-bootstrap/Accordion';
import Axios from 'axios';
import splash from './ultilidades/splash'
import alerta from './ultilidades/alerta'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { miPersonal, } from './../redux/personalRedux'

function Cuenta(params) {
  const personalRedux = useSelector((state) => state.personal.value)
  const dispatch = useDispatch()
  const [enviar, setEnviar] = useState(0)

  const [per_id, set_per_id] = useState(0)
  const [per_rfc, set_per_rfc] = useState('')
  const [per_curp, set_per_curp] = useState('')
  const [per_nombre, set_per_nombre] = useState('')
  const [per_apellidop, set_per_apellidop] = useState('')
  const [per_apellidom, set_per_apellidom] = useState('')
  const [per_pass, set_per_pass] = useState('')
  const [per_passw, set_per_passw] = useState('')
  const [per_passa, set_per_passa] = useState('')
  const [per_correo, set_per_correo] = useState('')
  const [per_institucional, set_per_institucional] = useState('')
  const [per_sexo, set_per_sexo] = useState('')
  const [per_direccion, set_per_direccion] = useState('')
  const [per_estado, set_per_estado] = useState('')
  const [per_municipio, set_per_municipio] = useState('')
  const [per_cp, set_per_cp] = useState('')
  const [per_tel, set_per_tel] = useState('')
  const [per_cel, set_per_cel] = useState('')
  const [per_tel_oficina, set_per_tel_oficina] = useState('')
  const [per_extension, set_per_extension] = useState('')
  const [per_nacionalidad, set_per_nacionalidad] = useState('México')
  const [per_nac_estado, set_per_nac_estado] = useState('')
  const [per_nac_municipio, set_per_nac_municipio] = useState('')
  //const [per_nac_fecha, set_per_nac_fecha] = useState('')
  const [per_sangre, set_per_sangre] = useState('')
  const [per_estado_civil, set_per_estado_civil] = useState('')
  const [per_numero_empleado, set_per_numero_empleado] = useState('')
  
  const [documento, setDocumento] = useState('')
  const [per_foto, set_per_foto] = useState('')
  let poner_rol = ''
  if(localStorage._ROL==='RH'){
    poner_rol = 'USER'
  }
  const [per_rol, set_per_rol] = useState(poner_rol)
  const [per_status, set_per_status] = useState('')
  const [per_fecha_ingreso_gobierno, set_per_fecha_ingreso_gobierno] = useState('')
  const [per_fecha_ingreso, set_per_fecha_ingreso] = useState('')
  const [per_fecha_baja, set_per_fecha_baja] = useState('')
  const [per_baja_motivo, set_per_baja_motivo] = useState('')
  const [per_c_area, set_per_c_area] = useState('')
  const [per_c_nivel, set_per_c_nivel] = useState('')
  const [per_c_jefe, set_per_c_jefe] = useState('')
  const [c_jefe, set_c_jefe] = useState([])
  const [recomendado, set_recomendado] = useState(0)
  const [per_c_recomendado, set_per_c_recomendado] = useState('')
  const [per_otro_recomendado, set_per_otro_recomendado] = useState('')
  const [per_recursos, set_per_recursos] = useState('')
  const [per_politica, set_per_politica] = useState('')

  //const [listaPersonal, setListaPersonal] = useState([])
  const misDatos = () => {
    let filtro = []
    if(personalRedux.length>0){
      if(params.usuario>0){
        filtro = personalRedux.filter(p => p.per_id===params.usuario)[0]
      }else{
        filtro = personalRedux.filter(p => p.per_rfc===localStorage.usuario)[0]
      }
      set_per_id(filtro.per_id)
      set_per_rfc(filtro.per_rfc)
      set_per_curp(filtro.per_curp)
      set_per_nombre(filtro.per_nombre)
      set_per_apellidop(filtro.per_apellidop)
      set_per_apellidom(filtro.per_apellidom)
      set_per_nacionalidad(filtro.per_nacionalidad)
      set_per_nac_estado(filtro.per_nac_estado)
      set_per_nac_municipio(filtro.per_nac_municipio)
      set_per_sangre(filtro.per_sangre)
      set_per_estado_civil(filtro.per_estado_civil)
      set_per_sexo(filtro.per_sexo)
      set_per_foto(filtro.per_foto)
      set_per_correo(filtro.per_correo)
      set_per_institucional(filtro.per_institucional)
      if(filtro.per_tel>0){set_per_tel(filtro.per_tel)}
      if(filtro.per_cel>0){set_per_cel(filtro.per_cel)}
      if(filtro.per_tel_oficina>0){set_per_tel_oficina(filtro.per_tel_oficina)}
      if(filtro.per_extension>0){set_per_extension(filtro.per_extension)}
      set_per_estado(filtro.per_estado)
      set_per_municipio(filtro.per_municipio)
      set_per_cp(filtro.per_cp)
      set_per_direccion(filtro.per_direccion)
      if(filtro.per_numero_empleado>0){set_per_numero_empleado(filtro.per_numero_empleado)}
      set_per_rol(filtro.per_rol)
      set_per_status(filtro.per_status)
      set_per_fecha_ingreso_gobierno(filtro.per_fecha_ingreso_gobierno)
      set_per_fecha_ingreso(filtro.per_fecha_ingreso)
      set_per_fecha_baja(filtro.per_fecha_baja)
      set_per_baja_motivo(filtro.per_baja_motivo)
      set_per_c_area(filtro.per_c_area)
      set_per_c_nivel(filtro.per_c_nivel)

      if(filtro.per_c_jefe>0){
        set_per_c_jefe(filtro.per_c_jefe)
        set_c_jefe(personalRedux.filter(p => p.per_id===filtro.per_c_jefe))
      }

      if(filtro.per_c_recomendado){
        set_recomendado(1)
        if(parseInt(filtro.per_c_recomendado)>0){
          set_per_c_recomendado(parseInt(filtro.per_c_recomendado))
        }else{
          set_per_c_recomendado('Otro')
          set_per_otro_recomendado(filtro.per_c_recomendado)
        }
      }
      set_per_recursos(filtro.per_recursos)
      set_per_politica(filtro.per_politica)
    }
  }
  const selec = () => {
    window.H5_loading.show();
    var pasar = new URLSearchParams();
    pasar.append('_USUARIO', localStorage.usuario);
    pasar.append('_ROL', localStorage._ROL);
    pasar.append('_Activa', localStorage._Activa);

    Axios.post(`${window.ws}wsSelectRH.php`, pasar)
      .then(response => {
        if(response.data.elError===1){
          dispatch(miPersonal(response.data.lista_array))

          let lista_filtrada = response.data.lista_array.filter(p => eval(params.condicion)) //eslint-disable-line
          params.setDatosUsuario(lista_filtrada)
        }else{
          //alerta('error', response.data.mensaje)
          console.log(response.data.mensaje)
        }
      })
      .catch(error => {
        //alerta('error', ''+error)
        console.log(error)
      })
      .then(function() {
        window.H5_loading.hide();
      })
  }
/*
  const selecUsuario = () => {
    window.H5_loading.show();
    var pasar = new URLSearchParams();
    pasar.append('_USUARIO', localStorage.usuario);
    pasar.append('_ROL', localStorage._ROL);
    pasar.append('_Activa', localStorage._Activa);
    pasar.append('id', 'usuario');
    //params.usuario

    Axios.post(`${window.ws}wsSelectRH.php`, pasar)
      .then(response => {
        if(response.data.elError===1){
          //setListaPersonal(response.data.lista_array)
          //alerta('success', 'si')
        }else{
          //alerta('error', response.data.mensaje)
          console.log(response.data.mensaje)
        }
      })
      .catch(error => {
        //alerta('error', ''+error)
        console.log(error)
      })
      .then(function() {
        window.H5_loading.hide();
      })
  }
  */

  useEffect(() => {
    //return () => {
      if(!params.modal){
        splash()
      }
      if(!params.recursos || params.usuario>0){
        misDatos()
      }
      /*
      if(params.usuario>0 || !params.usuario){
        selecUsuario()
      }
      */
    //}
  }, [personalRedux]);

  const handleSubmit = (event) => {
    event.preventDefault()
    setEnviar(1)
    let condicion = !per_rfc || !per_curp || !per_nombre || !per_apellidop || !per_apellidom || !per_correo || !per_sexo || !per_estado || !per_municipio || !per_cp || per_cp===0 || !per_nacionalidad || !per_nac_estado || !per_nac_municipio || (params.modal && (per_recursos==="" || per_politica==="" || !per_rol || !per_status)) || (params.recursos===1 && (!per_pass || !per_passw))
    if(condicion){
      alerta('error','Un campo requerido esta vacío');
    }else if(window.validarRfc(per_rfc.toUpperCase())===false){
      alerta('error','Ingrese un RFC valido');
    }else if(window.validarCurp(per_curp.toUpperCase())===false){
      alerta('error','Ingrese un CURP valido');
    }else{
      window.H5_loading.show();
      var pasar = new URLSearchParams();
      pasar.append('_USUARIO', localStorage.usuario);
      pasar.append('_ROL', localStorage._ROL);
      pasar.append('_Activa', localStorage._Activa);
      
      pasar.append('per_id', per_id);
      pasar.append('per_rfc', per_rfc);
      pasar.append('per_curp', per_curp);
      pasar.append('per_nombre', per_nombre);
      pasar.append('per_apellidop', per_apellidop);
      pasar.append('per_apellidom', per_apellidom);
      pasar.append('per_pass', per_pass);
      pasar.append('per_passw', per_passw);
      pasar.append('per_passa', per_passa);
      pasar.append('per_correo', per_correo);
      pasar.append('per_institucional', per_institucional);
      pasar.append('per_sexo', per_sexo);
      pasar.append('per_direccion', per_direccion);
      pasar.append('per_estado', per_estado);
      pasar.append('per_municipio', per_municipio);
      pasar.append('per_cp', per_cp);
      pasar.append('per_tel', per_tel);
      pasar.append('per_cel', per_cel);
      pasar.append('per_tel_oficina', per_tel_oficina);
      pasar.append('per_extension', per_extension);
      pasar.append('per_nacionalidad', per_nacionalidad)
      pasar.append('per_nac_estado', per_nac_estado);
      pasar.append('per_nac_municipio', per_nac_municipio);
      pasar.append('per_sangre', per_sangre);
      pasar.append('per_estado_civil', per_estado_civil);
      pasar.append('per_numero_empleado', per_numero_empleado);
      
      pasar.append('documento', documento);
      pasar.append('per_rol', per_rol);
      pasar.append('per_status', per_status);
      pasar.append('per_fecha_ingreso_gobierno', per_fecha_ingreso_gobierno);
      pasar.append('per_fecha_ingreso', per_fecha_ingreso);
      pasar.append('per_fecha_baja', per_fecha_baja);
      pasar.append('per_baja_motivo', per_baja_motivo);
      pasar.append('per_c_area', per_c_area);
      pasar.append('per_c_nivel', per_c_nivel);
      pasar.append('per_c_jefe', per_c_jefe);
      if(per_c_recomendado==="Otro"){
        pasar.append('per_c_recomendado', per_otro_recomendado);
      }else{
        pasar.append('per_c_recomendado', per_c_recomendado);
      }
      pasar.append('per_recursos', per_recursos);
      pasar.append('per_politica', per_politica);

      if(params.modal){
        pasar.append('modal', params.modal.toString());
        pasar.append('recursos', params.recursos);
      }
      Axios.post(`${window.ws}wsEmpleados.php`, pasar)
        .then(response => {
          if(response.data.elError===1){
            alerta('success',''+response.data.mensaje);
            setDocumento('')
            set_per_pass('')
            set_per_passw('')
            set_per_passa('')
            if(params.modal){
              //params.setModal(false)
              if(params.usuario>0){
                selec()
              }
            }
          }else{
            alerta('error',''+response.data.mensaje);
          }
        })
        .catch(error => {
          console.log(error)
        })
        .then(function() {
          window.H5_loading.hide();
        })
    }  
  };

  return (
    <div className="contenido_general">
      <div className="max_width padding_width">
        {
          params.modal===true ?
            null
          :
            <div className="titulos_intranet">
              Mi cuenta
            </div>
        }
        <Form onSubmit={handleSubmit}>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Información personal</Accordion.Header>
              <Accordion.Body>
                <div className="formulario_dos">
                  <div className="de_formulario_dos">
                    <div className="titulos_formulario">
                      Datos generales
                    </div>
                    <Form.Group>
                      <Form.Label>*Nombre:</Form.Label>
                      <Form.Control type="text" name="nombre_fer" placeholder="Nombre(s)" value={per_nombre} onChange={(event) => {set_per_nombre(event.target.value)}} required/>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>*Apellido paterno:</Form.Label>
                      <Form.Control type="text" value={per_apellidop} onChange={(event) => {set_per_apellidop(event.target.value)}} required/>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>*Apellido materno:</Form.Label>
                      <Form.Control type="text" value={per_apellidom} onChange={(event) => {set_per_apellidom(event.target.value)}} required/>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>*Registro Federal de Contribuyentes (RFC):</Form.Label>
                      <Form.Control type="text" value={per_rfc} onChange={(event) => {set_per_rfc(event.target.value)}} required disabled={per_id>0 ? true : false}/>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>*Clave Única de Registro de Población (CURP):</Form.Label>
                      <Form.Control type="text" value={per_curp} onChange={(event) => {set_per_curp(event.target.value)}} required/>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>*Nacionalidad:</Form.Label>
                      <Form.Select onChange={(event) => set_per_nacionalidad(event.target.value)} required>
                        <option selected={per_nacionalidad==="" ? true : false}></option>
                        {
                          window._my_pais.map((item, i) =>
                            <option key={i} selected={per_nacionalidad===item.nombre ? true : false}>{item.nombre}</option>
                          )
                        }
                      </Form.Select>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>*Estado de nacimiento:</Form.Label>
                      <Form.Select onChange={(event) => set_per_nac_estado(event.target.value)} required>
                        <option selected={per_nac_estado==="" ? true : false}></option>
                        {
                          window._my_estado.map((item, i) =>
                            <option key={i} selected={per_nac_estado===item.estado ? true : false}>{item.estado}</option>
                          )
                        }
                      </Form.Select>
                    </Form.Group>
                    {
                      per_nac_estado ?
                        <Form.Group>
                          <Form.Label>*Municipio de nacimiento:</Form.Label>
                          <Form.Select onChange={(event) => set_per_nac_municipio(event.target.value)} required>
                            <option selected={per_nac_municipio==="" ? true : false}></option>
                            {
                              window._my_estado.filter(p => p.estado===per_nac_estado)[0].municipios.map((item, i) =>
                                <option key={i} selected={per_nac_municipio===item ? true : false}>{item}</option>
                              )
                            }
                          </Form.Select>
                        </Form.Group>
                      :
                        null
                    }
                    <Form.Group>
                      <Form.Label>Tipo de sangre:</Form.Label>
                      <Form.Select onChange={(event) => set_per_sangre(event.target.value)}>
                        <option selected={per_sangre==="" ? true : false}></option>
                        {
                          window.sangre.map((item, i) =>
                            <option key={i} selected={per_sangre===item ? true : false}>{item}</option>
                          )
                        }
                      </Form.Select>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Estado civil:</Form.Label>
                      <Form.Select onChange={(event) => set_per_estado_civil(event.target.value)}>
                        <option selected={per_estado_civil==="" ? true : false}></option>
                        {
                          window.civil.map((item, i) =>
                            <option key={i} selected={per_estado_civil===item ? true : false}>{item}</option>
                          )
                        }
                      </Form.Select>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>*Sexo:</Form.Label>
                      <div className="flex_botones">
                        <div id={per_sexo==='Hombre' ? 'boton_marca' : null} onClick={() => set_per_sexo('Hombre')}>Hombre</div>
                        <div id={per_sexo==='Mujer' ? 'boton_marca' : null} onClick={() => set_per_sexo('Mujer')}>Mujer</div>
                      </div>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Fotografía:</Form.Label>
                      <Form.Control onChange={(e) =>{
                        if(!e.target.files[0]) {
                          alerta('error','Un campo requerido está vacio');
                        }else{
                          let condicion = e.target.files[0].type === "image/png" || e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/jpg"
                          let leyenda = 'PNG o JPG'
                          if(condicion){
                            window.H5_loading.show();

                            const config = {
                              headers: {
                                  'content-type': 'multipart/form-data'
                              }
                            }

                            const data = new FormData();
                            data.append('enviado', 'cuenta');
                            //data.append('que', 1);
                            data.append('file', e.target.files[0]);
                            data.append('_USUARIO', localStorage.usuario);
                            data.append('_ROL', localStorage._ROL);
                            data.append('_Activa', localStorage._Activa);

                            Axios.post(window.ws+'wsDocumento.php', data, config)
                            .then(response => {
                              if(response.data.elError===1){
                                alerta('success',response.data.mensaje)
                                setDocumento(response.data.documento)
                              }else{
                                alerta('error',response.data.mensaje)
                              }
                            })
                            .catch(error => {
                              //alerta('error',''+error);
                              console.log(error)
                            })
                            .then(function() {
                              window.H5_loading.hide();
                            })
                          }else{
                            alerta('error','El archivo debe estar en formato '+leyenda);
                          }
                        }
                      }} id="file-upload" type="file" accept=".png, .jpg"/>
                      {
                        documento || per_foto ?
                          <a href={`${window.ws}documentos/fotos/${documento ? `${localStorage.usuario}_TEMPORAL.${documento}` : `F_${per_id}.${per_foto}`}`} target="_new" className="icono_archivo">
                            <img src="img/foto.png" title="Ver fotografía" alt="Ver fotografía" className="hvr-push"/>
                          </a>
                        :
                          null
                      }
                    </Form.Group>
                  </div>
                  <div className="de_formulario_dos m_titulo_for">
                    <div className="titulos_formulario">
                      Datos de contacto
                    </div>
                    <Form.Group>
                      <Form.Label>*Correo electrónico:</Form.Label>
                      <Form.Control type="text" placeholder="Correo personal" value={per_correo} onChange={(event) => {set_per_correo(event.target.value)}} required/>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Correo electrónico institucional:</Form.Label>
                      <Form.Control type="text" placeholder="@hidalgo.gob.mx" value={per_institucional} onChange={(event) => {set_per_institucional(event.target.value)}}/>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Teléfono de casa:</Form.Label>
                      <Form.Control type="number" placeholder="Solo número 10 digitos" minlength="10" maxlength="10" value={per_tel} onChange={(event) => {set_per_tel(event.target.value)}}/>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Celular:</Form.Label>
                      <Form.Control type="number" placeholder="Solo número 10 digitos" minlength="10" maxlength="10" value={per_cel} onChange={(event) => {set_per_cel(event.target.value)}}/>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Teléfono de oficina:</Form.Label>
                      <Form.Control type="number" placeholder="Solo número 10 digitos" minlength="10" maxlength="10" value={per_tel_oficina} onChange={(event) => {set_per_tel_oficina(event.target.value)}}/>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Extensión:</Form.Label>
                      <Form.Control type="number" placeholder="Solo número" value={per_extension} onChange={(event) => {set_per_extension(event.target.value)}}/>
                    </Form.Group>
                    <div className="titulos_formulario m_titulo_for">
                      Domicilio
                    </div>
                    <Form.Group>
                      <Form.Label>*Estado:</Form.Label>
                      <Form.Select onChange={(event) => set_per_estado(event.target.value)} required>
                        <option selected={per_estado==="" ? true : false}></option>
                        {
                          window._my_estado.map((item, i) =>
                            <option key={i} selected={per_estado===item.estado ? true : false}>{item.estado}</option>
                          )
                        }
                      </Form.Select>
                    </Form.Group>
                    {
                      per_estado ?
                        <Form.Group>
                          <Form.Label>*Municipio:</Form.Label>
                          <Form.Select onChange={(event) => set_per_municipio(event.target.value)} required>
                            <option selected={per_municipio==="" ? true : false}></option>
                            {
                              window._my_estado.filter(p => p.estado===per_estado)[0].municipios.map((item, i) =>
                                <option key={i} selected={per_municipio===item ? true : false}>{item}</option>
                              )
                            }
                          </Form.Select>
                        </Form.Group>
                      :
                        null
                    }
                    <Form.Group>
                      <Form.Label>*Código postal:</Form.Label>
                      <Form.Control type="number" placeholder="Solo número 5 digitos" minlength="5" maxlength="5" value={per_cp} onChange={(event) => {set_per_cp(event.target.value)}} required/>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Dirección:</Form.Label>
                      <Form.Control type="text" placeholder="Calle, número, colonia o fraccionamiento" value={per_direccion} onChange={(event) => {set_per_direccion(event.target.value)}}/>
                    </Form.Group>
                  </div>
                </div>
                <div className="formulario_dos m_titulo_for">
                  <div className="de_formulario_dos">
                    <div className="titulos_formulario">
                      Datos laborales
                    </div>
                    <Form.Group>
                      <Form.Label>Número de empleado:</Form.Label>
                      <Form.Control type="number" placeholder="Solo número" value={per_numero_empleado} onChange={(event) => {set_per_numero_empleado(event.target.value)}}/>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Fecha de ingreso a la Secretaría:</Form.Label>
                      <Form.Control type="date" placeholder="aaaa-mm-dd" value={per_fecha_ingreso} onChange={(event) => {set_per_fecha_ingreso(event.target.value)}} disabled={params.modal===true ? null : true}/>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Fecha de ingreso a Gobierno:</Form.Label>
                      <Form.Control type="date" placeholder="aaaa-mm-dd" value={per_fecha_ingreso_gobierno} onChange={(event) => {set_per_fecha_ingreso_gobierno(event.target.value)}} disabled={params.modal===true ? null : true}/>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Área:</Form.Label>
                      <Form.Select onChange={(event) => set_per_c_area(event.target.value)} disabled={params.modal===true ? null : true}>
                        <option selected={per_c_area==="" ? true : false}></option>
                        {
                          window._my_organismos.map((item, i) =>
                            <option key={i} selected={per_c_area===item.id ? true : false} value={item.id}>{item.nombre}</option>
                          )
                        }
                      </Form.Select>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Nivel:</Form.Label>
                      <Form.Select onChange={(event) => set_per_c_nivel(event.target.value)} disabled={params.modal===true ? null : true}>
                        <option selected={per_c_nivel==="" ? true : false}></option>
                        {
                          window._my_puestos.map((item, i) =>
                            <option key={i} selected={per_c_nivel===item.id ? true : false} value={item.id}>{item.cargo} - {item.nombramiento} - {item.nivel}</option>
                          )
                        }
                      </Form.Select>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Jefe inmediato:</Form.Label>
                      <Typeahead
                        id="basic-typeahead-multiple"
                        //multiple
                        labelKey="completo"
                        onChange={(event) => {
                          if(typeof event==='object' && event.length>0){
                            //alert('no'+event[0].per_id)
                            set_per_c_jefe(event[0].per_id)
                          }else{
                            set_per_c_jefe("")
                          }
                          set_c_jefe(event)
                        }}
                        options={personalRedux.filter(p => p.per_status>2 && p.per_id!==per_id)}
                        selected={c_jefe}
                        emptyLabel="No hay resultados."
                        disabled={params.modal===true ? null : true}
                      />
                    </Form.Group>
                    {
                      params.modal===true ?
                        <div>
                          <Form.Group>
                            <Form.Label>Recomendado:</Form.Label>
                            <div className="flex_botones">
                              <div id={recomendado===1 ? 'boton_marca' : null} onClick={() => set_recomendado(1)}>Sí</div>
                              <div id={recomendado===2 ? 'boton_marca' : null} onClick={() => set_recomendado(2)}>No</div>
                            </div>
                          </Form.Group>
                          {
                            recomendado===1 ?
                              <Form.Group>
                                <Form.Label>¿Quién lo recomendo?</Form.Label>
                                <Form.Select onChange={(event) => set_per_c_recomendado(event.target.value)}>
                                  <option selected={per_c_recomendado==="" ? true : false}></option>
                                  {
                                    personalRedux.map((item, i) =>
                                      <option key={i} selected={per_c_recomendado===item.per_id ? true : false} value={item.per_id}>{item.completo}</option>
                                    )
                                  }
                                  <option selected={per_c_recomendado==="Otro" ? true : false}>Otro</option>
                                </Form.Select>
                                {
                                  per_c_recomendado==="Otro" ?
                                    <div>
                                      <div className="espacio_input"/>
                                      <Form.Control type="text" placeholder="Nombre de la persona que lo recomendo" value={per_otro_recomendado} onChange={(event) => {set_per_otro_recomendado(event.target.value)}}/>
                                    </div>
                                  :
                                    null
                                }
                              </Form.Group>
                            :
                              null
                          }
                          <Form.Group>
                            <Form.Label>*¿Maneja recursos financieros?</Form.Label>
                            <div className="flex_botones">
                              <div id={per_recursos===1 ? 'boton_marca' : null} onClick={() => set_per_recursos(1)}>Sí</div>
                              <div id={per_recursos===0 ? 'boton_marca' : null} onClick={() => set_per_recursos(0)}>No</div>
                            </div>
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>*¿Opera políticamente?</Form.Label>
                            <div className="flex_botones">
                              <div id={per_politica===1 ? 'boton_marca' : null} onClick={() => set_per_politica(1)}>Sí</div>
                              <div id={per_politica===0 ? 'boton_marca' : null} onClick={() => set_per_politica(0)}>No</div>
                            </div>
                          </Form.Group>
                        </div>
                      :
                        null
                    }
                  </div>
                  <div className="de_formulario_dos">
                    {
                      params.modal===true ?
                        <div>
                          <div className="titulos_formulario">
                            Datos de la cuenta
                          </div>
                          {
                            localStorage._ROL==='ADMIN' ?
                              <Form.Group>
                                <Form.Label>*Rol del sistema:</Form.Label>
                                <Form.Select onChange={(event) => set_per_rol(event.target.value)} required>
                                  <option selected={per_rol==="" ? true : false}></option>
                                  <option selected={per_rol==="ADMIN" ? true : false} value="ADMIN">Administrador (ADMIN)</option>
                                  <option selected={per_rol==="RH" ? true : false} value="RH">Recursos humanos (RH)</option>
                                  <option selected={per_rol==="RM" ? true : false} value="RM">Recursos materiales (RM)</option>
                                  <option selected={per_rol==="COM" ? true : false} value="COM">Comunicación (COM)</option>
                                  <option selected={per_rol==="USER" ? true : false} value="USER">Usuario normal (USER)</option>
                                </Form.Select>
                              </Form.Group>
                            :
                              null
                          }
                          <Form.Group>
                            <Form.Label>*Estado de la cuenta:</Form.Label>
                            <Form.Select onChange={(event) => set_per_status(event.target.value)} required>
                              <option selected={per_status==="" ? true : false}></option>
                              {/*<option selected={per_status===0 ? true : false} value={0}>Nuevo</option>*/}
                              <option selected={per_status===2 ? true : false} value={2}>Inactivo</option>
                              <option selected={per_status===3 ? true : false} value={3}>Activo</option>
                              {params.recursos===1 ? null : <option selected={per_status===1 ? true : false} value={1}>Baja</option>}
                            </Form.Select>
                            {
                              per_status===1 ?
                                <div>
                                  <Form.Label>Fecha de la baja:</Form.Label>
                                  <Form.Control type="date" placeholder="aaaa-mm-dd" value={per_fecha_baja} onChange={(event) => {set_per_fecha_baja(event.target.value)}}/>
                                  <Form.Label>Motivo de la baja:</Form.Label>
                                  <Form.Control as="textarea" rows={3} placeholder="Dercribe el motivo de la baja." value={per_baja_motivo} onChange={(event) => {set_per_baja_motivo(event.target.value)}}/>
                                </div>
                              :
                                null
                            }
                          </Form.Group>
                        </div>
                      :
                        null
                    }
                    <div className="titulos_formulario">
                      {params.recursos===1 ? "Contraseña" : "Cambiar contraseña"}
                    </div>
                    <Form.Group>
                      {
                        params.modal===true ?
                          null
                        :
                          <div>
                            <Form.Control type="password" placeholder="Contraseña actual" value={per_passa} onChange={(event) => {set_per_passa(event.target.value)}}/>
                            <div className="espacio_input"/>
                          </div>
                      }
                      <Form.Control type="password" placeholder={params.recursos===1 ? "*Contraseña" : "Contraseña nueva"} value={per_pass} onChange={(event) => {set_per_pass(event.target.value)}}/>
                      <div className="espacio_input"/>
                      <Form.Control type="password" placeholder={params.recursos===1 ? "*Confirmar contraseña" : "Confirmar contraseña nueva"} value={per_passw} onChange={(event) => {set_per_passw(event.target.value)}}/>
                    </Form.Group>
                  </div>
                </div>
                <div className="espacio_input"/>
                <div className="campos">
                  Campos obligatorios (*)
                </div>
                <div id="cl"></div>
                <div className="fin_botones"><Button type="submit" variant="success">Guardar</Button></div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Form>
      </div>
    </div>
  );
}

export default Cuenta;
