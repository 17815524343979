const cerrarSesion = () => {
  if(localStorage.usuario){
    localStorage.removeItem('usuario')
    localStorage.removeItem('_ROL')
    localStorage.removeItem('_Nombre')
    localStorage.removeItem('_Activa')
  }
  //history.push('login');
  window.location.href = window.rutas;
}

export default cerrarSesion;