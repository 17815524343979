import { useEffect, useState } from 'react';

import Carousel from 'react-bootstrap/Carousel';
import "bootstrap/dist/css/bootstrap.min.css";
import Button from 'react-bootstrap/Button';
import Axios from 'axios';
import splash from './ultilidades/splash'
import alerta from './ultilidades/alerta'

function Login() {
  const [usuario, set_usuario] = useState("");
  const [contrasena, set_contrasena] = useState("");
  
  useEffect(() => {
    splash()
    if(localStorage._ROL){
      window.location.href = window.rutas+"inicio";
    }
  }, []);

  return (
    <div>
      <div className="inicio_car">
        <form className="login">
          <img src="img/logo.svg" alt="Intranet" title="Intranet"/>
          <div>
            <div className="titulo_login">Iniciar sesión con tu cuenta</div>
            Usuario:
            <div id="cl"></div>
            <input placeholder="Usuario (RFC):" type="text" name="usuario" onChange={(event) => {set_usuario(event.target.value)}} value={usuario}/>
            <div id="cl"></div>
            Contraseña:
            <div id="cl"></div>
            <input placeholder="Contraseña:" type="password" name="contrasena" onChange={(event) => {set_contrasena(event.target.value)}} value={contrasena}/>
            <Button variant="success" onClick={() => {
              if(usuario===''){
                alerta('error','Ingresa tu usuario');
              }else if(contrasena===''){
                alerta('error','Ingresa tu contraseña');
              }else{
                window.H5_loading.show();
                var params = new URLSearchParams();
                params.append('usuario', usuario);
                params.append('contrasena', contrasena);
                Axios.post(`${window.ws}wsLogin.php`, params)
                  .then(response => {
                    if(response.data.elError===1){
                      localStorage.setItem('usuario', usuario)
                      localStorage.setItem('_ROL', response.data.elRol)
                      localStorage.setItem('_Nombre', response.data.elNombre)
                      localStorage.setItem('_Activa', response.data.elActiva)
                      //alerta('success','si');
                      window.location.href = window.rutas+"inicio";
                    }else{
                      alerta('error',''+response.data.mensaje);
                    }
                  })
                  .catch(error => {
                    console.log(error)
                  })
                  .then(function() {
                    window.H5_loading.hide();
                  })
              }
            }}>
              Enviar
            </Button>
          </div>
        </form>
      </div>
      <Carousel fade controls={false} interval={3000} pause={false} indicators={false}>
        {
          window._my_carousel.map((item, i) =>
            <Carousel.Item key={i} className="car_img" style={{backgroundImage: `url(${item.foto})`}}/>
          )
        }
      </Carousel>
    </div>
  );
}

export default Login;
