import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import splash from './splash'
import Axios from 'axios';
import alerta from './alerta'
import { useSelector } from 'react-redux'

function Modales(params) {
  const personalRedux = useSelector((state) => state.personal.value)
  //const [otro, setOtro] = useState(0);
  const [modalOtro, setModalOtro] = useState(false);
  const [buzonSelect, setBuzonSelect] = useState("");
  const [otroMensaje, setOtroMensaje] = useState("");
  const [buzonMensaje, setBuzonMensaje] = useState("");

  useEffect(() => {
    //return () => {
      splash()
    //}
  }, []);

  let fechaSintesis
  if(params.modal.tipo==="sintesis"){
    fechaSintesis = new Date(`${params.modal.arreglo.filter(p => p.sin_id===params.modalID)[0].sin_fecha}T00:00`)
  }

  const Normas = () => {
    return(
      <table className="tabla_modales">
        <tr>
          <th>#</th>
          <th>Nombre</th>
          <th></th>
        </tr>
        {
          window._my_normateca.map((item, i) => 
            <tr key={i} onClick={() => {
              params.setModalID(item.id)
              if(params.modalID>0){
                setModalOtro(false)
              }
            }}>
              <td>{i+1}</td>
              <td>{item.nombre}</td>
              <td><img src="img/concesiones.png" className={params.modalID===item.id ? "sintesis_sel" : null} alt="Ver" title="Ver"/></td>
            </tr>
          )
        }
      </table>
    )
  }

  return (
    <div>
      <Modal
        show={params.modal.ver}
        onHide={() => {
          params.setModal({
            ver: false,
            tipo: "",
            url: "",
            titulo: "",
            arreglo: [],
          })
          setBuzonSelect("")
          setOtroMensaje("")
          setBuzonMensaje("")
        }}
        size={params.modal.tipo==="buzon" || params.modal.tipo==="cumples" || params.modal.tipo==="cumples_mes" || params.modal.tipo==="festivos_mes" ? null : "lg"}   
        className={params.modal.tipo==="sintesis" || params.modal.tipo==="frame" || (params.modal.tipo==="normateca" && params.modalID>0) ? "frame" : params.modal.tipo==="buzonQUITA" ? "modal_buzon" : null}
        style={{zIndex: modalOtro ? 3 : null}}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {
              params.modal.tipo==="sintesis" ?
                <div>
                  <div className="modal_sintesis">
                    <h8>{params.modal.titulo}</h8>
                    {params.modal.arreglo.length>1 ? <div onClick={() => setModalOtro(!modalOtro)}>Ver más</div> : null}
                  </div>
                  <div className="modal_fecha">{`${window.dia[fechaSintesis.getDay()]} de ${fechaSintesis.getDate()} de ${window.meses[fechaSintesis.getMonth()]}, ${fechaSintesis.getFullYear()}`}</div>
                </div>
              : params.modal.tipo==='normateca' && params.modalID>0 ?
                <div className="modal_sintesis">
                  <h8>{window._my_normateca.filter(p => p.id===params.modalID)[0].nombre}</h8>
                  {window._my_normateca.filter(p => p.id!==params.modalID).length>1 ? <div onClick={() => setModalOtro(!modalOtro)}>Ver más</div> : null}
                </div>
              :
                <h8>{params.modal.titulo}</h8>
            }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            params.modal.tipo==='frame' ?
              <iframe src={params.modal.url} className="modal_frame" title={params.modal.titulo}/>
            : params.modal.tipo==='normateca' ?
              params.modalID===0 ?
                <Normas/>
              :
                <iframe src={window._my_normateca.filter(p => p.id===params.modalID)[0].archivo} className="modal_frame" title={params.modal.titulo}/>
            : params.modal.tipo==='sintesis' ?
              <iframe src={params.modal.arreglo.filter(p => p.sin_id===params.modalID)[0].sin_url ? params.modal.arreglo.filter(p => p.sin_id===params.modalID)[0].sin_url : `${window.ws}documentos/sintesis/${params.modal.arreglo.filter(p => p.sin_id===params.modalID)[0].sin_id}.pdf`} className="modal_frame" title={params.modal.titulo}/>
            : params.modal.tipo==='organismos' ?
              <div className="modal_organismos">
                {
                  window._my_organismos.filter(p => p.id === params.modalID).map(item =>
                    <table className="tabla_organismos">
                      <tr>
                        <td>Nombre</td>
                        <td>{item.nombre}</td>
                      </tr>
                      <tr>
                        <td>Siglas</td>
                        <td>{item.siglas}</td>
                      </tr>
                      {
                        item.titular ?
                          <tr>
                            <td>Titular</td>
                            <td>{item.titular}</td>
                          </tr>
                        :
                          null
                      }
                      {
                        item.telefono ?
                          <tr>
                            <td>Teléfono</td>
                            <td>{item.telefono}</td>
                          </tr>
                        :
                          null
                      }
                      {
                        item.correo ?
                          <tr>
                            <td>Correo electrónico</td>
                            <td><a href={`mailto:${item.correo}`} className="sin_a" target="new">{item.correo}</a></td>
                          </tr>
                        :
                          null
                      }
                      {
                        item.direccion ?
                          <tr>
                            <td>Dirección</td>
                            <td>{item.direccion}</td>
                          </tr>
                        :
                          null
                      }
                      {
                        item.ubicacion ?
                          <tr>
                            <td>Ubicación</td>
                            <td>
                              <div className="web_modal_organismos">
                                <a href={item.ubicacion} target="new">
                                  <img src="img/ubicacion.svg" alt="Ubicación"/>
                                  Ver ubicación
                                </a>
                              </div>
                            </td>
                          </tr>
                        :
                          null
                      }
                      {
                        item.web ?
                          <tr>
                            <td>Sitio web</td>
                            <td>
                              <div className="web_modal_organismos">
                                <a href={item.web} target="new">
                                  <img src="img/www.svg" alt="www"/>
                                  Visitar el sitio
                                </a>
                              </div>
                            </td>
                          </tr>
                        :
                          null
                      }
                      {
                        item.infografia ?
                          <tr>
                            <td>Infografía</td>
                            <td>
                              <div className="web_modal_organismos">
                                <a href={item.infografia} target="new">
                                  <img src="img/infografia.svg" alt="Infografía"/>
                                  Ver infografía
                                </a>
                              </div>
                            </td>
                          </tr>
                        :
                          null
                      }
                    </table>
                  )
                }
              </div>
            : params.modal.tipo==='buzon' ?
              <Form>
                <Form.Group>
                  <Form.Label>*Tipo de mensaje:</Form.Label>
                  <Form.Select onChange={(event) => setBuzonSelect(event.target.value)} required>
                    <option value=""></option>
                    {
                      window._my_buzon.map((item, i) =>
                        <option key={i} value={item} selected={buzonSelect===item ? true : false}>{item}</option>
                      )
                    }
                  </Form.Select>
                </Form.Group>
                {buzonSelect==="Denuncia acoso" ? <h9>Tu mensaje solo lo vera el Secretario</h9> : null}
                {
                  buzonSelect==='Otro' ? 
                    <Form.Group>
                      <div className="espacio_input"/>
                      <Form.Control type="text" placeholder="*Especifique el tipo de mensaje:" value={otroMensaje} onChange={(event) => {setOtroMensaje(event.target.value)}}/>
                    </Form.Group>
                  :
                    null
                }
                <Form.Group>
                  <Form.Label>*Mensaje:</Form.Label>
                  <Form.Control as="textarea" rows={3} placeholder="Escribe tu mensaje." value={buzonMensaje} onChange={(event) => {setBuzonMensaje(event.target.value)}} required/>
                </Form.Group>
                <div className="campos">
                  Campos obligatorios (*)
                </div>
                <div className="espacio_input"/>
                <Button variant="dark" onClick={() => {
                  if(!buzonSelect || !buzonMensaje || (buzonSelect==='Otro' && !otroMensaje)){
                    alerta('error','Un campo requerido esta vacío');
                  }else{
                    window.H5_loading.show();
                    var pasar = new URLSearchParams();
                    pasar.append('_USUARIO', localStorage.usuario);
                    pasar.append('_ROL', localStorage._ROL);
                    pasar.append('_Activa', localStorage._Activa);
                    let filtrado = personalRedux.filter(p => p.per_rfc===localStorage.usuario)
                    let area = ""
                    if(filtrado[0].per_comi){
                      area = window._my_organismos.filter(p => p.id===filtrado[0].per_comi)[0].nombre
                    }else if(filtrado[0].per_c_area){
                      area = window._my_organismos.filter(p => p.id===filtrado[0].per_c_area)[0].nombre
                    }
                    pasar.append('area', area);
                    pasar.append('buzonSelect', buzonSelect);
                    pasar.append('buzonMensaje', buzonMensaje);
                    pasar.append('otroMensaje', otroMensaje);
                    Axios.post(`${window.ws}wsBuzon.php`, pasar)
                      .then(response => {
                        if(response.data.elError===1){
                          alerta('success',''+response.data.mensaje);
                        }else{
                          alerta('error',''+response.data.mensaje);
                        }
                      })
                      .catch(error => {
                        console.log(error)
                      })
                      .then(function() {
                        window.H5_loading.hide();
                      })
                  }
                }}>
                    Enviar
                </Button>
              </Form>
            : params.modal.tipo==='festivos_mes' ?
              <table className="tabla_modales">
                {
                  params.modal.arreglo.map((item, i) => {
                    let miFecha = new Date(`${item.fecha}T00:00`)
                    return(
                      <tr key={i}>
                        <td className={item.fecha===window.ndate ? 'num_fecha_hoy' : 'num_fecha'}>
                          {window.abmeses[miFecha.getMonth()]}
                          <br/>                         
                          {item.fecha.slice(8, 10)}
                        </td>
                        <td>
                          {item.fecha===window.ndate ? <h16>{item.festejo}</h16> : <h14>{item.festejo}</h14>}
                          {
                            item.oficial>0 ? 
                              <h15>Oficial</h15>
                            : 
                              <h15>No oficial</h15>
                          }
                        </td>
                      </tr>
                    )
                  })
                }
              </table>
            : params.modal.tipo==='cumples_mes' ?
              <table className="tabla_modales">
                {
                  params.modal.arreglo.filter(p => p.per_rfc.slice(6, 8)===window.nmes).map((item, i) => {
                    let miFecha = new Date(`${window.ano}-${item.per_rfc.slice(6, 8)}-${item.per_rfc.slice(8, 10)}T00:00`)
                    return(
                      <tr key={i}>
                        <td className="num_fecha">
                          {window.abmeses[miFecha.getMonth()]}
                          <br/>                         
                          {item.per_rfc.slice(8, 10)}
                        </td>
                        <td>
                          <h14>{item.completo}</h14>
                          {
                            item.comi_area ? 
                              <h15>{window._my_organismos.filter(p => p.id===item.comi_area)[0].nombre}</h15>
                            : item.per_c_area ?
                              <h15>{window._my_organismos.filter(p => p.id===item.per_c_area)[0].nombre}</h15>
                            : null
                          }
                        </td>
                      </tr>
                    )
                  })
                }
              </table>
            : params.modal.tipo==='cumples' ?
              <Form>
                <Form.Group>
                  <Form.Label>*Mensaje:</Form.Label>
                  <Form.Control as="textarea" rows={3} placeholder={`Enviar felicitación a ${personalRedux.filter(p => p.per_id===params.modalID)[0].per_nombre}.`} value={buzonMensaje} onChange={(event) => {setBuzonMensaje(event.target.value)}} required/>
                </Form.Group>
                <div className="campos">
                  Campos obligatorios (*)
                </div>
                <div className="espacio_input"/>
                <Button variant="dark" onClick={() => {
                  if(!buzonMensaje){
                    alerta('error','Un campo requerido esta vacío');
                  }else{
                    //window.H5_loading.show();
                    const cc_cual = "Felicitación"
                    const filtrado_envia = personalRedux.filter(p => p.per_rfc===localStorage.usuario)[0]
                    const filtrado_recibe = personalRedux.filter(p => p.per_id===params.modalID)[0]

                    const cc_nombre = filtrado_envia.completo.replace(' ', '%20')

                    let cc_recibe = filtrado_recibe.per_correo
                    if(filtrado_recibe.per_institucional){
                      cc_recibe = filtrado_recibe.per_institucional
                    }

                    let cc_envia = filtrado_envia.per_correo
                    if(filtrado_envia.per_institucional){
                      cc_envia = filtrado_envia.per_institucional
                    }

                    let cc_area = ""
                    if(filtrado_envia.per_comi){
                      cc_area = window._my_organismos.filter(p => p.id===filtrado_envia.per_comi)[0].nombre.replace(' ', '%20')
                    }else if(filtrado_envia.per_c_area){
                      cc_area = window._my_organismos.filter(p => p.id===filtrado_envia.per_c_area)[0].nombre.replace(' ', '%20')
                    }

                    const cc_mensaje = buzonMensaje.replace(' ', '%20')

                    cc_recibe = 'fers.delagarza@gmail.com' //
                    cc_envia = 'fer_182_day@hotmail.com' //
                    
                    const enviar_correo = `<iframe width="0" height="0" src="http://189.254.1.56/INTRANET_NO_BORRAR.php?cual=${cc_cual}&nombre=${cc_nombre}&area=${cc_area}&recibe=${cc_recibe}&envia=${cc_envia}&mensaje=${cc_mensaje}" style="width:0;height:0;"></iframe>`
                    alerta('success','Tu mensaje de felicitación ha sido enviado'+enviar_correo);
                    /*
                    setBuzonMensaje("")
                    params.setModal({
                      ver: false,
                      tipo: "",
                      url: "",
                      titulo: "",
                      arreglo: [],
                    })
                    */
                  }
                }}>
                    Enviar
                </Button>
              </Form>
            :
              'no'
          }
        </Modal.Body>
      </Modal>
      <Modal
        show={modalOtro}
        onHide={() => setModalOtro(false)}
        className="otro_modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {params.modal.tipo==='normateca' ? "Normateca" : params.modal.titulo==='Reporte económico' ? 'Reportes económicos recientes' : 'Síntesis recientes'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body >
          {
            params.modal.tipo==='normateca' ?
              <Normas/>
            : params.modal.tipo==="sintesis" ?
              <table className="tabla_modales">
                <tr>
                  <th>#</th>
                  <th>Nombre</th>
                  <th>Fecha</th>
                  <th></th>
                </tr>
                {
                  params.modal.arreglo.map((item, i) => {
                    let miFecha = new Date(`${item.sin_fecha}T00:00`)
                    //let miFecha = new Date(`${item.not_fecha.split(" ")[0]}T00:00`)
                    return(
                      <tr key={i} onClick={() => {
                        params.setModalID(item.sin_id)
                        setModalOtro(false)
                      }}>
                        <td>{i+1}</td>
                        <td>{params.modal.titulo}</td>
                        <td>{`${window.dia[miFecha.getDay()]} ${miFecha.getDate()} de ${window.meses[miFecha.getMonth()]}, ${miFecha.getFullYear()}`}</td>
                        <td><img src={params.modal.titulo==='Reporte económico' ? "img/mapa_hgo.svg" : "img/sintesis.svg"} className={params.modalID===item.sin_id ? "sintesis_sel" : null} alt="Ver" title="Ver"/></td>
                      </tr>
                    )
                  })
                }
              </table>
            :
              null
          }
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Modales;